import { PaletteColor, ThemeOptions, createTheme } from '@mui/material'
import type { MuiCustomPaletteOptions } from 'types'

declare module '@mui/material/styles' {
  interface Palette {
    highlight: PaletteColor;
  }
  interface PaletteOptions {
    highlight: PaletteColor;
  }
}

declare module '@mui/material/styles' {
  interface PaletteOptions extends MuiCustomPaletteOptions {}
  interface Palette extends MuiCustomPaletteOptions {}
}

declare module '@mui/material' {
  interface SwitchPropsColorOverrides {
    highlight: true;
  }
  interface CheckboxPropsColorOverrides {
    highlight: true;
  }
  interface RadioPropsColorOverrides {
    highlight: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h4: false;
    h5: false,
    h6: false,
    button: false,
    caption: false,
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    yle: true
  }
}

const { palette } = createTheme()
const { augmentColor } = palette
const createColor = (mainColor: string): PaletteColor => augmentColor({ color: { main: mainColor } })

const activeColor = '#eaeaea'
const passiveColor = '#b7b7b7'
const highlightColor = '#FF8A04'

const muiTheme: ThemeOptions = {
  // hack
  // hide support button behind upload info
  zIndex: {
    snackbar: 3000000,
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
    h1: {
      fontSize: '1.0rem',
      color: activeColor,
      fontWeight: 500,
    },
    h2: {
      fontSize: '0.95rem',
      fontWeight: 500,
    },
    h3: {
      fontSize: '0.9rem',
      lineHeight: 1.5,
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: '0.875rem',
      color: activeColor,
      fontWeight: 500,
      lineHeight: 1.3,
    },
    subtitle2: {
      fontSize: '0.875rem',
      color: '#8F8F8F',
      fontWeight: 500,
      lineHeight: 1.3,
    },
    body1: {
      fontSize: '0.85rem',
      lineHeight: 1.2,
      fontWeight: 500,
    },
    // body2 is used by many built in components such as TableCell, etc
    body2: {
      fontSize: '0.80rem',
      lineHeight: 1.1,
      fontWeight: 500,
    },
  },
  palette: {
    mode: 'dark',
    text: {
      primary: '#c8c8c8',
    },
    background: {
      default: '#363636',
      paper: '#272727',
    },
    primary: {
      main: '#272727',
    },
    secondary: {
      main: activeColor,
    },
    highlight: createColor(highlightColor),
    icon: {
      passive: passiveColor,
      active: activeColor,
    },
    yle: {
      main: '#01809c',
      dark: '#01809c',
      contrastText: activeColor,
    },
    mpSecondary: {
      main: '#35958a',
    },
    mpError: {
      main: '#e74c3c',
    },
    mpTitle: {
      main: '#ffffff',
    },
  },
  shape: {
    borderRadius: 10,
  },
  components: {
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: '#2d2d2d',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'unset',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: '8px !important',
          paddingRight: '8px !important',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: '36px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: '36px',
          minWidth: 0,
          fontWeight: 500,
          '&.MuiButtonBase-root': {
            padding: '4px 8px',
            fontSize: '0.8rem',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: 0,
          textTransform: 'none',
          transition: 'background-color 0.3s',
          borderRadius: '20px',
          '& .text': {
            transition: 'color 0.3s',
          },
          '& .icon': {
            transition: 'fill 0.3s',
          },
          '&:hover': {
            backgroundColor: 'rgb(231,211,252, 0.15)',
          },
        },
      },
    },
    MuiAvatarGroup: {
      styleOverrides: {
        avatar: {
          border: 0,
          marginLeft: -12,
        },
      },
    },
    MuiChip: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          '&.MuiChip-filledDefault': {
            backgroundColor: '#B09E8F',
            color: '#202424ff',
          },
          '&.MuiChip-filledDefault > svg': {
            fill: '#363636',
          },
          '&.MuiChip-clickable': {
            transition: 'opacity 0.3s',
            '&:hover': {
              opacity: 0.8,
            },
          },
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        color: 'highlight',
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'highlight',
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          minWidth: '150px',
        },
      },
    },
    MuiSwitch: {
      defaultProps: {
        color: 'highlight',
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          backgroundColor: highlightColor,
          color: '#000',
        },
      },
    },
    MuiTable: {
      defaultProps: {
        size: 'small',
      },
    },
  },
}

export default muiTheme
