import 'react-app-polyfill/stable'
import { createRoot } from 'react-dom/client'
import { Provider as ReduxProvider } from 'react-redux'
import { RouterProvider } from 'react-router-dom'
import * as serviceWorker from './serviceWorker'
import router from './router'
import store from './app/modules/store'
import './index.css'

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)

root.render(
  <ReduxProvider store={store}>
    <RouterProvider router={router} />
  </ReduxProvider>,
)

serviceWorker.unregister()
