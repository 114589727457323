import { createStore, applyMiddleware, Reducer } from 'redux'

import logger from 'redux-logger'
import thunk from 'redux-thunk'
import reducer from './reducer'
import defaultState from './defaultState'

function configureStore() {
  let middleware = [thunk]

  if (process.env.REACT_APP_STAGE === 'dev') {
    middleware = [...middleware, logger]
  }

  const localAppState = localStorage.getItem('appState')
  const stateFromLocalStorage = localAppState ? JSON.parse(localAppState) : null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const persistedState: any = defaultState

  const allowedKeys = [
    'profile',
    'showDeleted',
  ]

  if (stateFromLocalStorage) {
    Object.keys(stateFromLocalStorage).forEach((key) => {
      if (allowedKeys.includes(key)) {
        persistedState[key] = stateFromLocalStorage[key]
      }
    })
  }

  const store = createStore(
    reducer as Reducer,
    persistedState,
    applyMiddleware(...middleware),
  )

  store.subscribe(() => {
    const state = store.getState()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const toLocalStorage: any = {}
    Object.keys(state).forEach((key) => {
      if (allowedKeys.includes(key)) {
        toLocalStorage[key] = state[key]
      }
    })
    localStorage.setItem('appState', JSON.stringify(toLocalStorage))
  })

  return store
}

const store = configureStore()

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export default store
