const defaultDataFields = [
  {
    title: 'Title',
    active: true,
    field: 'metadata.title',
    weight: 8,
  },
  {
    title: 'ID',
    active: false,
    field: 'id.keyword',
    weight: 8,
  },
  {
    title: 'External ID',
    active: false,
    field: 'external_id.keyword',
    weight: 8,
  },
  {
    title: 'Description',
    active: true,
    field: 'metadata.description',
    weight: 6,
  },
  {
    title: 'Tags',
    active: true,
    field: 'metadata.tags',
    weight: 5,
  },
  {
    title: 'AI Tags',
    active: true,
    field: 'ai_tags',
    weight: 4,
  },
  {
    title: 'Keywords',
    active: true,
    field: 'exiftool_metadata.Keywords',
    weight: 3,
  },
  {
    title: 'Copyright',
    active: true,
    field: 'exiftool_metadata.Copyright',
    weight: 2,
  },
  {
    title: 'Creator',
    active: true,
    field: 'exiftool_metadata.Creator',
    weight: 1,
  },
]

export const customFieldWeight = 7

export default defaultDataFields
